@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #18bcf3;

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4 px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: '*';
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}

.ant-form-vertical .ant-form-item-label > label {
  font-weight: normal;
}

.ant-btn {
  border-radius: 4px;
}

html {
  font-size: 15px;
}
body {
  color: #343434;
  font-family: 'Noto Sans JP' !important;
}

#root {
  min-width: 1366px;
  overflow: hidden;
  position: relative;
}

.ant-modal {
  color: #404040;
}

.message_success_custom {
  .ant-message-notice-content {
    background: #eaffe2;
    border: 1px solid #34c759;
    border-radius: 4px;
    box-shadow: 0px 0px 16px rgba(198, 198, 200, 0.5);
    width: 650px;
    text-align: left;
    position: relative;
  }
}

.message_error_custom {
  .ant-message-notice-content {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 16px rgba(198, 198, 200, 0.5);
    width: 650px;
    text-align: left;
    position: relative;
  }
}

.icon-close-custom-message {
  position: absolute !important;
  right: 7px;
  top: 13px !important;
  cursor: pointer;
  z-index: 1000;
}

.ant-table {
  color: #343434;
}

.ant-breadcrumb span {
  font-size: 15px;
  vertical-align: middle;
}

.ant-switch {
  background-color: #ff3b30;
}

.ant-switch-checked {
  background-color: #34c759;
}

.link-click {
  color: #007aff;
  cursor: pointer;
  user-select: none;
}

.ant-breadcrumb-link {
  color: #343434;
}
.ant-tabs-tab-btn {
  font-size: 15px;
  font-weight: normal;
}

.extend-right-component {
  position: absolute;
  top: 19px;
  right: 16px;
  display: flex;
  align-items: center;
}

.ant-tabs-tab:hover {
  opacity: 0.8;
  color: #404040;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: #fff;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
  color: #18bcf3;
}
.ant-table-cell {
  word-break: break-word;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: rgba(64, 158, 255, 0.1) !important;
}

.text-split {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  width: 100%;
}

textarea.ant-input:not([disabled]) {
  padding-right: 20px;
}

.text-area-scroll {
  .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    right: 18px;
  }
  .ant-input[disabled] {
    padding-right: 0;
  }
}

@primary-color: #18bcf3;