$space-size-base: 8px;
.report-admin {
  .ant-select-selector {
    height: 40px;
  }

  .table-row-total-account {
    background-color: #ffdeeb;
  }
  .table-row-total {
    background-color: rgba(64, 158, 255, 0.1);
  }

  .table-row-light {
    background-color: rgba(144, 147, 153, 0.1);
  }

  .button-range-state-active {
    border: 1px solid #18bcf3;
    color: #18bcf3;
    height: 42px;
  }

  .button-range-state-not-active {
    border: 1px solid #e0e0e0;
    color: black;
    height: 42px;
  }
  thead > tr > th {
    background-color: white;
  }

  .range-picker {
    border-radius: 0.5 * $space-size-base;
    width: 100%;
    height: 42px;
    margin-left: 20px;
    .ant-picker-input {
      input {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        letter-spacing: 0.5px;
      }
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      font-size: 20px;
    }

    .ant-picker-clear {
      margin-right: 28px;
    }
  }
}

.dropdown-range-picker-custom .ant-picker-panel-container {
  display: flex;
  flex-direction: row-reverse;
}

.dropdown-range-picker-custom .ant-picker-footer {
  min-width: auto !important;
  // width: 200px;
}

.dropdown-range-picker-custom .ant-picker-preset .ant-tag-blue {
  color: #343434 !important;
  background: #fff !important;
  border: none;
}

.ant-picker-ranges {
  display: grid;
}
