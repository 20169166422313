@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

$font-family: 'Noto Sans JP' !important;
$primary-background: #001529;
$primary-color: lightseagreen;
$og-btn-color: #282828;
$oa-btn-color: #18bcf3;

$content-width: 1366px;
$font-size-base-px: 15px;

$font-size-8: 1rem * (8px / $font-size-base-px); //8px;
$font-size-10: 1rem * (10px / $font-size-base-px); //10px;
$font-size-11: 1rem * (11px / $font-size-base-px); //11px;
$font-size-12: 1rem * (12px / $font-size-base-px); //12px;
$font-size-13: 1rem * (13px / $font-size-base-px); //13px;
$font-size-14: 1rem * (14px / $font-size-base-px); //14px;
$font-size-15: 1rem; //15px;
$font-size-16: 1rem * (16px / $font-size-base-px); //16px;
$font-size-17: 1rem * (17px / $font-size-base-px); //17px;
$font-size-18: 1rem * (18px / $font-size-base-px); //18px;
$font-size-20: 1rem * (20px / $font-size-base-px); //20px;
$font-size-21: 1rem * (21px / $font-size-base-px); //21px;
$font-size-24: 1rem * (24px / $font-size-base-px); //24px;
$font-size-25: 1rem * (25px / $font-size-base-px); //25px;

$font-size-36: 1rem * (36px / $font-size-base-px); //36px;
$font-size-48: 1rem * (48px / $font-size-base-px); //48px;

$font-size-none: 0rem;

$space-size-tiny: 4px;
$space-size-base: 8px;
$space-size-large: 14px;
$space-size-xl: 32px;

$line-height-rate-nano: 1.2;
$line-height-rate-micro: 1.3;
$line-height-rate-small: 1.4;
$line-height-rate-base: 1.5;
$line-height-rate-medium: 1.6;
$line-height-rate-large: 1.7;
$line-height-rate-xlarge: 1.8;

// form
$input-height-30: 30px;

.survey-question {
  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 10px);
  }

  .table-question {
    margin-top: 2 * $space-size-base;

    .ant-table-thead>tr>th {
      &::before {
        display: none;
      }

      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;

      .ic-info {
        color: rgba(0, 0, 0, 0.54);
      }

      .btn-add-question {
        width: 127px;

        span:nth-child(2) {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          /* identical to box height, or 18px */

          /* System / White */

          color: #ffffff;
        }
      }
    }

    .ant-table-tbody>tr>td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .txt-question {
        cursor: pointer;
      }

      .ant-switch-disabled {
        opacity: 0.5;
        cursor: default;
      }

      .visibility {
        background-color: #ff3b30;
        height: 10px;
        line-height: 10px;

        .ant-switch-handle {
          width: $space-size-base;
          height: $space-size-base;
          top: 1px;
          cursor: default;
          // left: calc(100% - 10px);
        }
      }

      .visibility.ant-switch-checked {
        background-color: #34c759;
      }

      .ic-delete,
      .ic-edit {
        cursor: pointer;
      }
    }
  }
}

// .row-dragging {
//   background: #fafafa;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   z-index: 1111;
// }

.option-dragging,
.row-dragging {
  z-index: 1111;
}

.option-dragging td {
  padding: 1.5 * $space-size-base;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-15;
  line-height: $line-height-rate-base;
  /* identical to box height, or 25px */

  letter-spacing: 0.1px;

  /* Text / Primary */

  color: #343434;

  .input-answer {
    padding: 0 8px;
    border-radius: 0.5 * $space-size-base;
    width: 540px;
    margin: 0 -0.5 * $space-size-base;
    height: 40px;

    &:disabled {
      background-color: #fff;
      color: #343434;
      font-size: $font-size-15;
    }
  }
}

.row-dragging td {
  padding: 1.5 * $space-size-base;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-15;
  line-height: $line-height-rate-base;
  /* identical to box height, or 25px */

  letter-spacing: 0.1px;

  /* Text / Primary */

  color: #343434;
  width: 500px;

  .ant-switch-disabled {
    opacity: 1;
  }

  .visibility {
    background-color: #ff3b30;
    height: 10px;
    line-height: 10px;

    .ant-switch-handle {
      width: $space-size-base;
      height: $space-size-base;
      top: 1px;
    }
  }

  .visibility.ant-switch-checked {
    background-color: #34c759;
  }

  .ic-delete {
    margin-top: -5px;
  }
}

.row-dragging.drag-visible {
  visibility: visible;
}

// Modal Form Incident Question

.md-form-incident-question {
  width: 1120px !important;

  .ant-switch {
    background-color: #ff3b30;
    height: 10px;
    line-height: 10px;

    .ant-switch-handle {
      width: $space-size-base;
      height: $space-size-base;
      top: 1px;
    }
  }

  .ant-switch-checked {
    background-color: #34c759;
  }

  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    /* or 28px */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    /* Primary Text */

    color: #343434;
  }

  .ant-form-item-label {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    letter-spacing: 0.1px;

    /* Text / Primary */

    color: #343434;
    display: inline-flex;
  }

  .ant-form-item-required {
    flex-direction: row-reverse;

    &::before {
      margin-left: 0.5 * $space-size-base;
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 2 * $space-size-base;
  }

  .wrap-type {
    // height: 122px;
    background: rgba(64, 158, 255, 0.1);
    border-radius: 0.5 * $space-size-base;
    padding: $space-size-base;
    margin-bottom: 2 * $space-size-base;

    .ant-form-item {
      margin-bottom: 0 !important;
    }

    .wrap-txt-setting {
      // padding: $space-size-base;
      margin-bottom: $space-size-base;

      .ant-typography {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
      }
    }

    .radio-type {
      .ant-radio-wrapper {
        padding: 7px;
        min-width: 660px;

        span {
          &:nth-child(2) {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            line-height: $line-height-rate-base;

            letter-spacing: 0.1px;

            /* Primary Text */

            color: #343434;
          }
        }
      }
    }

    .ant-radio-wrapper-checked {
      border-radius: 0.5 * $space-size-base;

      .ant-radio-checked .ant-radio-inner {
        border-color: #007aff;

        &:after {
          background-color: #007aff;
        }
      }
    }
  }

  .input-question {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    /* identical to box height, or 25px */

    letter-spacing: 0.1px;
  }

  .form-required {
    background: rgba(64, 158, 255, 0.1);
    border-radius: 0.5 * $space-size-base;
    height: 50px;

    .ant-form-item-control-input {
      margin-top: 5px;
      padding: 0 10px;
    }

    .ant-form-item-control-input-content {
      .ant-form-item {
        margin-bottom: 0;
      }

      display: flex;
      flex-direction: row;
      align-items: center;

      .wrap-txt {
        margin-left: 10px;
        margin-top: 3px;

        .ant-typography {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }
      }
    }
  }

  .form-visibility {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .wrap-txt {
        margin-right: 1.5 * $space-size-base;

        .ant-typography {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          /* or 20px */

          letter-spacing: 0.5px;

          /* Text / Primary */

          color: #343434;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .answer-block {
    margin-top: $space-size-base;
    height: 410px;
    border-radius: 0.5 * $space-size-base;
    display: flex;
    flex-direction: row;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 0.5 * $space-size-base;

    .answer-type,
    .answer {
      margin-bottom: 0;

      .ant-form-item-label {
        padding: $space-size-base;
        margin-bottom: $space-size-base;

        label {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1px;

          /* Primary Text */

          color: #343434;
        }
      }
    }

    .answer-type {
      background: rgba(64, 158, 255, 0.1);
      width: 332px;
      display: flex;
      flex-direction: column;
      flex-flow: column;

      .radio-answer-type {
        width: 100%;

        .space-answer-type {
          width: 100%;

          .ant-space-item {
            .space-label-answer-type {
              margin-left: 10px;
            }

            .ant-radio-wrapper {
              width: 100%;
              height: 40px;
              display: flex;
              align-items: center;

              .ant-radio {
                display: none;
              }

              .ant-typography {
                font-style: normal;
                font-weight: normal;
                font-size: $font-size-15;
                line-height: $line-height-rate-base;
                /* identical to box height, or 25px */

                letter-spacing: 0.1px;

                /* Text / Primary */

                color: #343434;
              }
            }

            .ant-radio-wrapper-checked {
              background: #acd3ff;
              box-shadow: inset -2px 0px 0px #007aff;
            }
          }
        }
      }
    }

    .answer {
      margin-left: $space-size-base;
      width: 750px;
      display: flex;
      flex-direction: column;
      flex-flow: column;

      .ant-form-item-label {
        label {
          &::after {
            display: inline-block;
            margin-right: 0.5 * $space-size-base;
            color: #ff4d4f;
            line-height: 1;
            content: '*';
          }
        }
      }

      .wrap-answer {
        margin: 0 2 * $space-size-base;

        .form-list-answers {
          .space-input-add {
            margin-bottom: $space-size-base;

            .input-add-new {
              width: 562px;
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              /* identical to box height, or 25px */

              letter-spacing: 0.1px;
            }

            .btn-add {
              font-style: normal;

              font-size: $font-size-15;
              line-height: $line-height-rate-nano;
            }
          }

          .table-answers {
            .ant-table-thead>tr>th {
              display: none;
            }

            .ant-table-tbody>tr>td {
              border-bottom: none;
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              padding: $space-size-base 0;
              /* identical to box height, or 25px */

              letter-spacing: 0.1px;

              .input-answer {
                width: 524px;
                margin-left: $space-size-base;

                &:disabled {
                  background-color: #fff;
                  color: #343434;
                  font-size: $font-size-15;
                }
              }

              .ic-delete {
                cursor: pointer;
                margin-left: 36px;
              }
            }
          }
        }

        .txt-answer {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* or 25px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }
      }
    }
  }
}

.survey-question-drag {
  display: flex;

  td:first-child {
    width: 48px;
  }

  td:nth-child(2) {
    width: calc(100% - 728px);
  }

  td:nth-child(3) {
    width: 220px;
  }

  td:nth-child(4) {
    width: 90px;
  }

  td:nth-child(5) {
    width: 190px;
  }

  td:nth-child(6) {
    width: 180px;
  }
}