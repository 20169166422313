$space-size-base: 8px;
.table-base {
  position: relative;
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: '';
    width: 0;
  }

  .ant-table-thead > tr > th {
    background: #ffffff;
    padding: 7px 16px;
    text-transform: uppercase;
    font-size: 14px;
    height: 48px;
  }
  .ant-table-tbody > tr > td {
    padding: 12.5px 16px;
  }
  .button-add {
    position: absolute;
    user-select: none;
    top: 58px;
    right: 0;
  }
  .ant-empty-img-default {
    width: 100px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;
  }
  .ant-select-selection-search-input {
    min-height: 42px;
  }
  .ant-select-selector {
    min-height: 42px;
    width: 400px;
  }

  .button-range-state-active {
    border: 1px solid #18bcf3;
    color: #18bcf3;
    height: 42px;
  }
  .button-range-state-not-active {
    border: 1px solid #e0e0e0;
    color: black;
    height: 42px;
  }

  .range-picker {
    border-radius: 0.5 * $space-size-base;
    width: 100%;
    height: 42px;
    margin-left:20px;
    .ant-picker-input {
      input {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        letter-spacing: 0.5px;
      }
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      font-size: 20px;
    }

    .ant-picker-clear {
      margin-right: 28px;
    }
  }
}
