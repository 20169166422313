.upload-area > .ant-upload {
  border: 1px dashed #007aff;
  width: 100%;
  height: 134px;
  min-width: 500px;
}

.upload-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(64, 158, 255, 0.1);
}
